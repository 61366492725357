.fullPage {
  width: 100%;
  margin-top: -20px;
  padding-top: 20px;
  background-color: #f2f2f2;
}

.form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.logo-image {
  width: 50%;
}

.logo-container {
  margin-bottom: 20px;
}

.login-page {
  width: 100% !important;
}

.form-card {
  background-color: white;
  width: 70% !important;
  border-radius: 16px;
  margin-top: 1em;
}

.registration-form {
  padding: 0px 130px 100px 95px;
}

.registration-input-group {
  width: 60%;
}

ul .passwordOptions {
  text-align: left;
  width: 400px;
}
li .passwordOptions {
  display: list-item;
  width: 400px;
  padding: 0 0%;
  line-height: 30px;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .login-card {
    background-color: white;
    width: 100% !important;
    border-radius: 16px;
    margin-top: 5em;
  }
  .login-form {
    padding: 40px 20px 40px 20px;
    width: 100%;
  }
  .registration-form {
    padding: 0px 20px 40px 20px;
    text-align: center;
  }
  .form-card {
    width: 100% !important;
    margin-top: 0em;
  }
  .registration-input-group {
    width: 100%;
  }
  .card-header {
    padding: 20px 20px 20px 20px;
  }
  .bp-info-text {
    background-color: whitesmoke;
    border-radius: 16px;
    text-align: center;
    width: 100%;
    padding: 10px;
  }
  li .passwordOptions {
    width: 100%;
  }
  ul .passwordOptions {
    width: 100%;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .login-card {
    background-color: white;
    width: 100% !important;
    border-radius: 16px;
    margin-top: 5em;
  }
  .login-form {
    padding: 10px, 10px, 10px, 10px;
    width: 100%;
  }
  .form-card {
    width: 100% !important;
  }
  .registration-input-group {
    width: 100%;
  }
  .registration-form {
    padding: 0px 20px 40px 20px;
    width: 100%;
  }
  .bp-info-text {
    background-color: whitesmoke;
    border-radius: 16px;
    text-align: center;
    width: 100%;
    padding: 10px;
  }
}

.bp-info-text {
  background-color: whitesmoke;
  border-radius: 16px;
  text-align: center;
  width: 60%;
  padding: 10px;
}
