.fullPage {
  width: 100%;
  height: 100%;
  margin-top: -20px;
  background-color: #f2f2f2;
}

.login-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.logo-image {
  width: 50%;
}

.logo-container {
  margin-bottom: 20px;
}

.login-page {
  width: 100% !important;
}

.login-card {
  background-color: white;
  width: 70% !important;
  border-radius: 16px;
  margin-top: 5em;
}

.reset-password-card {
  background-color: white;
  border-radius: 16px;
}

.login-form {
  padding: 100px 130px 100px 95px;
}

.reset-password-form {
  padding: 20px 130px 20px 95px;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .login-card {
    background-color: white;
    width: 100% !important;
    border-radius: 16px;
    margin-top: 5em;
  }
  .login-form {
    padding: 40px 20px 40px 20px;
    width: 100%;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .login-card {
    background-color: white;
    width: 100% !important;
    border-radius: 16px;
    margin-top: 5em;
  }
  .login-form {
    padding: 10px, 10px, 10px, 10px;
    width: 100%;
  }
}
