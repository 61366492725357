/* --- UploadProfilImage Component --- */

.upload-container {
  width: 250px;
  .form-group {
    .img-container {
      width: 150px;
      height: 150px;

      .img-with-overlay {
        display: block;
        width: 100%;
        width: 150px;
        height: 150px;
      }

      .hidden {
        display: none;
      }

      .img-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 150px;
        height: 150px;
        opacity: 0;
        background-color: grey;
        opacity: 0.6;
        #progress {
          width: 200px;
          height: 20px;
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
