@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '~video-react/styles/scss/video-react';

@font-face {
  font-family: 'Cerebri Sans';
  src: url(../src/assets/fonts/cerebri-sans/CerebriSans-Light.eot);
  src: url(../src/assets/fonts/cerebri-sans/CerebriSans-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url(../src/assets/fonts/cerebri-sans/CerebriSans-Regular.eot);
  src: url(../src/assets/fonts/cerebri-sans/CerebriSans-Regular.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url(../src/assets/fonts/cerebri-sans/CerebriSans-Bold.eot);
  src: url(../src/assets/fonts/cerebri-sans/CerebriSans-Bold.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Frontage-Regular';
  src: url(../src/assets/fonts/Frontage-Regular.otf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Frontage-Bold';
  src: url(../src/assets/fonts/Frontage-Bold.otf);
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

a:hover {
  text-decoration: none !important;
}

h4 {
  font-family: 'Source Sans Pro', sans-serif;
  color: #47604e;
}

.right {
  float: right;
}

.round-img {
  border-radius: 50%;
  object-fit: cover;
}

.post-text {
  color: black;
  padding-top: 10px;
}

.circular {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin: 0px 5px 0px 5px;
}

.circular img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.userProfilePic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin: 0px 5px 0px 5px;
}

.userProfilePic img {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.post-user-info {
  align-items: center;
  padding-bottom: 10px;
}

.post-user-name {
  text-transform: none;
}

.timeline-user-info {
  padding: 10px 10px;
}

.dashboard-user-info {
  text-align: left;
}

.dashboard-separator {
  margin-top: 3em;
  margin-bottom: 3em;
}

.centered {
  justify-items: center;
}

.text-align-center {
  text-align: center;
}

.no-background {
  box-shadow: none !important;
}

.profile-edit-card {
  background: white;
}

.profile-edit-header {
  padding-left: 10px;
}

.btn-ac-green {
  margin-left: 20px;
  background-color: #57786c !important;
  color: white;
  font-family: 'Source Sans Pro';
  font-size: 1.4em !important;
  align-self: center;

  :hover {
    background-color: #e4f2ec;
    color: black;
  }
}

.spacer-bottom {
  margin-bottom: 20px;
}

.spacer-top {
  margin-top: 3em;
}

.add-post-modal {
  width: 70%;
}

.card-description {
  color: black !important;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  // margin-bottom: 12em !important;
}

main {
  flex: 1;
}

.nav-dropdown-link {
  font-size: 1.1em !important;
  font-family: 'Cerebri Sans';
  font-weight: 600;
  font-style: normal;
}

.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
  color: rgb(87, 120, 108) !important;
}

/* Sticky footer styles
-------------------------------------------------- */

#footer {
  padding-top: 12em !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 12em;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

.nav-item {
  font-size: 1.5em;
  font-family: 'Cerebri Sans';
  font-weight: 600;
  padding-right: 0px;
  font-style: normal;
}

.nav-link {
  font-size: 0.8em !important;
  font-family: 'Cerebri Sans';
  font-weight: 600;
  padding-right: 0px;
  font-style: normal;
}

.body-header {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}

textarea.form-control {
  font-size: 1.2em;
}

.comment-input {
  width: 70% !important;
  height: 20% !important;
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}

.post-date {
  font-size: smaller;
}

.profile-posts {
  margin-left: 30px;
}

.profile-options {
  border: 1px solid black;
  height: 700px;
  background-color: white !important;
  font-family: 'Cerebri Sans';
}

.post-options {
  float: right;
}

.profile-edit-box {
  border: 1px solid black;
  border-left-width: 0em;
  background-color: white !important;
  height: 700px;
}

.profile-edit-menu {
  -webkit-box-direction: normal;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  list-style: none;
  flex-basis: 236px;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
}

.profile-edit-options {
  color: rgba(var(--i1d, 38, 38, 38), 1);
  -webkit-box-direction: normal;
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  display: flex;
  align-items: center;

  :hover {
    background-color: lightgray;
  }
}

.align-icon {
  display: flex;
  align-items: center !important;
}

.edit-option-selected {
  font-weight: bold;
  border-left: 2px solid black;
  font-family: 'Cerebri Sans';
  color: #e75833;
}

.profile-edit-option-text {
  -webkit-box-direction: normal;
  list-style: none;
  margin: 0;
  border: 0;
  font: inherit;
  border-left: 2px solid transparent;
  display: block;
  font-size: 16px;
  height: 100%;
  line-height: 20px;
  padding: 16px 16px 16px 10px;
  width: 100%;
  text-decoration: none;
}

.small-text {
  font-size: 12px;
}

.comment-img {
  width: 25px;
}

.card__avatar {
  margin-bottom: 1em;
}

.header-content {
  position: absolute;
  left: 0;
  height: 100%;
  width: 45%;
  z-index: 264;
  text-align: none !important;
  position: none;
}

.header-content-wrapper {
  padding-left: 25%;
  height: 55%;
  margin-top: 11%;
}

.intro-button-wrap {
  border-radius: 4px;
  background-color: white;
  width: auto;
  height: auto;
  z-index: 258;
  display: inline-flex;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 25px;
  padding-left: 25px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 0px;
  color: #47604e;
  border: 3px solid #f9916b;
}

.intro-button {
  font-size: 30px;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #47604e;
  line-height: 1.611;
  text-align: left;
  z-index: 260;
}

.subTitle {
  font-family: 'Raleway';
  color: #fff;
  font-weight: 700;
  line-height: 1.3;
  text-align: left;
  width: 100%;
  margin-top: 20px;
  z-index: 262;
  font-size: 33px;
}

.heavySubTitle {
  font-family: 'Raleway';
  color: #fff;
  font-weight: 700;
  font-size: 17px;
}

.titleText {
  font-family: CerebriSans, sans-serif !important;
}

.hero-logo {
  height: 5%;
  width: auto;
  object-fit: contain;
}

.small-separator {
  background-color: #e75833;
  width: 40px;
  height: 2px;
  z-index: 114;
  display: none;
}

.footerSeparator {
  background-color: #000;
  width: 100%;
  height: 2px;
  border: black solid 1px;
  border-radius: 10%;
  z-index: 114;
  margin-bottom: 40px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  width: 70%;
  padding-bottom: 10px;
  padding-top: 1em;
  color: #e75833;
  font-size: 1.7em;
  font-family: 'Raleway';
  font-weight: 700 !important;
  font-size: 2.3em !important;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #e75833;
}

.separator::before {
  margin-right: 0.5em;
}

.separator::after {
  margin-left: 0.5em;
}

.separator-left {
  margin: 20px;
  overflow: hidden;
  color: #e75833;
  text-align: left;
  font-size: 3em !important;
  font-family: 'Raleway';
  font-weight: 700 !important;
  font-size: 2.3em !important;
}

.separator-left:before {
  display: block;
  float: right;
  margin-top: 0.8em;
  /* half the line-height */
  border-top: 2px solid #e75833;
  width: 65%;
  content: '';
}

.panel-header {
  padding-top: 35px;
  font-size: 3em;
  font-family: 'Raleway', sans-serif;
  color: #47604e;
  font-weight: 700;
  line-height: 0.967;
  text-align: center;
  z-index: 242;
  padding-bottom: 20px;
}

.panel-header-2 {
  font-family: 'Pacifico', cursive;
  padding-top: 35px;
  font-size: 3em;
  color: rgb(231, 88, 51);
  line-height: 0.967;
  text-align: center;
  z-index: 242;
  padding-bottom: 20px;
}

.panel-header-3 {
  font-family: 'Frontage-Regular';
  padding-top: 35px;
  font-size: 2.3em;
  color: rgb(231, 88, 51);
  text-align: center;
  padding-bottom: 20px;
  letter-spacing: -7px;
}

.panel-header-4 {
  font-family: 'Pacifico', cursive;
  padding-top: 35px;
  font-size: 3em;
  color: white;
  line-height: 0.967;
  text-align: center;
  z-index: 242;
  padding-bottom: 20px;
}

.ac-text {
  font-size: 1.7em;
  font-family: 'Raleway', sans-serif;
  color: #47604e;
  font-weight: 500;
}

.ac-text-2 {
  font-size: 1.7em;
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 700;
}

.ac-text-3 {
  font-size: 1.2em;
  font-family: 'Raleway', sans-serif;
  color: #47604e;
  font-weight: 500;
}

.ac-text-4 {
  font-size: 1.7em;
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: 500;
}

.ac-orange {
  color: rgb(231, 88, 51);
}

.single-panel {
  font-size: 1.7em;
  font-family: 'Raleway', sans-serif;
  color: #47604e;
  line-height: 1.526;
  text-align: left;
  z-index: 240;
  margin-left: 35px;
  margin-right: 35px;
}

.single-panel-head {
  font-family: 'Cerebri Sans';
  font-weight: 700;
  line-height: 1.526;
  font-size: 19px;
}

.heavy-bold {
  font-weight: bolder;
}

.the-solution-divider {
  position: relative;
  margin-top: 10em;
}

.the-solution-divider::before {
  position: absolute;
  pointer-events: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' viewBox='0 0 100 100' fill='rgb(218,218,218)' fill-opacity='1'%3E%3Cpolygon points='0,100 100,100 0,0'%3E%3C/polygon%3E%3C/svg%3E");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  height: 100px;
  top: -100px;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.the-solution-content {
  background-color: #dadada;
  padding-bottom: 20px;
  padding-top: 20px;
}

.hiw-button {
  background-color: rgb(228, 210, 69) !important;
  color: black !important;
  font-family: 'Cerebri Sans';
  font-size: 18px !important;
}

.hiwTxt {
  padding-top: 1em;
  font-family: Raleway;
  font-weight: 700;
  font-size: 1.3em;
  padding-left: 10%;
  padding-right: 20%;
}

.small-grey-text {
  font-size: 1em;
  font-weight: 500;
  color: grey;
  padding-right: 0px;
}

.ac-button {
  background-color: #e75833 !important;
  color: white !important;
  font-family: 'Cerebri Sans';
  font-size: 18px !important;
}

.social-links {
  padding-top: 20px;
  padding-bottom: 20px;
}

.newsletter-signup-container {
  padding: 10px 0px 40px 0px;
}

#newsletter-input {
  height: 40px;
  font-size: 14pt;
  margin: 0px 0px 15px 0px;
}

.reset-password-card {
  background-color: white;
  border-radius: 16px;
}

.blogbody {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  color: #666;
  max-width: 1000px !important;
  margin-left: auto;
  margin-right: auto;
}

.blogCardText {
  color: white;
  padding-top: 25%;
  font-family: 'Frontage Regular';
  font-weight: 700;
  font-size: 1.3em;
}

.blogCardSubText {
  color: white;
  padding-top: 0%;
  font-family: 'Raleway';
  font-weight: 500;
  font-size: 1.3em;
  margin-top: 15px;
}

.ralewayBold {
  font-family: 'Raleway' !important;
  font-weight: 700 !important;
  font-size: 1.6em !important;
}

.raleway {
  font-family: 'Raleway' !important;
  font-weight: 700 !important;
}

.commentActions {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.commentActions::after {
  border-bottom: '2px solid rgb(255, 0, 0)';
}

.notificationCircle {
  background: lightblue;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.auth-nav-item {
  margin-top: 2px;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
  .profile-options {
    border: 0px solid black;
    background-color: white !important;
    font-family: 'Cerebri Sans';
    width: 100%;
    margin-bottom: 15%;
  }

  .profile-posts {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }

  .header-content {
    width: 100%;
  }

  .header-content-wrapper {
    padding-left: 3%;
    padding-right: 4%;
    height: 40%;
    margin-top: 20%;
  }

  .subTitle {
    font-size: 30px;
  }

  .hero-logo {
    height: 7%;
    width: auto;
    object-fit: contain;
  }
}

.mkt-cta {
  width: 60%;
  padding-top: 20em;
}

.banner {
  margin: -20px 0px 20px 0px;
  height: 500px;
  text-align: center;
}

.banneroverlay {
  max-width: 100%;
  height: auto;
  padding-top: 200px;
  font-family: 'Frontage-Bold';
  font-weight: 700;
  letter-spacing: 0px;
  font-size: 3.5em;
  color: white;
}

.blogTitle {
  font-family: 'Frontage-Regular';
}

.blogBanner {
  height: 400px;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  margin-top: -20px;
  margin-bottom: 20px;
}

.blogBannerOverlay {
  max-width: 100%;
  height: auto;
  padding-top: 150px;
  font-family: 'Raleway';
  font-weight: 700;
  font-size: 3.5em;
  color: white;
}

.blogBannerShade {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  height: 500px;
}

.blogBannerOverlaySubtitle {
  max-width: 100%;
  height: auto;
  padding-top: 150px;
  font-family: 'Raleway';
  font-weight: 700;
  font-size: 3.5em;
  color: white;
}

.blogSeparator {
  background-color: #e75833;
  width: 70%;
  height: 10px;
  z-index: 114;
  justify-self: center;
}

.hiwImg {
  width: 40em;
  max-width: 80%;
}

.hiwImg1 {
  padding-top: 7em;
  width: 40em;
  max-width: 80%;
}

.hiwImg2 {
  padding-top: 13em;
  width: 40em;
  max-width: 80%;
}

.hiwAPImg {
  width: 25em;
}

.hiwAPcol {
  padding-top: 2em;
  padding-bottom: 2em;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.gs_feature_text {
  text-align: center;
}

.center-card {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.checkout-button {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.3em !important;
  border: solid black 1px;
}

.inputLabel {
  font-family: 'Source Sans Pro', sans-serif;
  color: #434143 !important;
  font-size: 1rem;
  display: block;
  padding-left: 20%;
  text-align: left;
}

.center-form {
  width: 100%;
  display: inline-block;
}

.notification-container {
  margin-top: 4%;
}

.awssld__content > img {
  height: 70% !important;
}

.awssld__content {
  background-color: rgb(87, 120, 108) !important;
}

.awssld__content {
  margin-top: 5em;
}

.float-center {
  justify-content: 'center';
  align-items: 'center';
  flex: 1;
}

.imgRight {
  float: right;
  object-fit: contain;
}

.imgLeft {
  float: left;
  object-fit: contain;
}

b {
  font-weight: bold !important;
}

.btn-share {
  margin-top: 30% !important;
}

.btn-preview-popup {
  border: 5px solid #91b4ae !important;
}

.fileList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: visible;
  zoom: 1;
}

.fileItem {
  min-width: 100%;
}

.footer-img {
  width: 40%;
}

.fc-event,
.fc-event-dot {
  background-color: #434143 !important;
}

.center-all {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hiw-button-2 {
  margin-top: 15%;
}

.contactUsRight {
  margin-left: 60%;
  margin-bottom: 8%;
}

.contactUsLeft {
  margin-right: 60%;
  margin-bottom: 8%;
}

.dropdown-menu {
  background-color: #364f44 !important;
}

.dropdown-item {
  color: white !important;
  font-weight: 600;
}

.dropdown-menu::before {
  color: #364f44 !important;
}

textarea {
  resize: none !important;
}

.editProfileTitle {
  font-family: 'Raleway';
  font-weight: 700;
}

.media .avatar {
  margin: 0 !important;
}

@media screen and (max-width: 991px) {
  .navbar-collapse:before {
    background: #141f16 !important;
    color: black !important;
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .navbar-collapse {
    background-color: #364f44 !important;
  }

  .contactUsRight {
    margin-left: 0;
    margin-bottom: 10%;
  }

  .contactUsLeft {
    margin-right: 0;
    margin-bottom: 10%;
  }

  .loginBtn {
    background-color: '#5CFFC0' !important;
    color: '#364f44 !important';
  }

  .a-navItem {
    margin: 0.7rem 1rem !important;
    width: 90%;
  }
}

.footerContainer {
  max-width: 100% !important;
  color: white;
}

.preAuthFooterContainer {
  padding-top: 2em;
  max-width: 100% !important;
  color: white;
  background-color: #57786c;
}

.alert-alert-custom {
  background-color: #f96446;
}

.youtubeEmbed {
  max-width: 100%;
}

ul {
  list-style-type: none;
}

// Only larger screens
@media screen and (min-width: 991px) {
  .supportPage {
    border: 1px solid black;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 40px;
    padding-top: 40px;
    border-radius: 20px;
    width: 50%;
    flex: 1 1 auto;
    text-align: left;
    display: inline-block;
    font-weight: 700;
  }
  .supportPage label {
    padding-left: 2%;
  }
  .loginBtn {
    background-color: #57786c !important;
  }
}

@media screen and (max-width: 991px) {
  .navbar-collapse .navbar-nav:not(.navbar-logo) .nav-link:not(.btn) {
    color: black !important;
    margin-bottom: -5px;
  }

  .last-nav-dropdown-link {
    margin-bottom: 10px;
  }

  .nav-item {
    color: black !important;
  }

  .nav-link {
    color: white !important;
  }

  .navbar .dropdown.show .dropdown-menu,
  .navbar .dropdown .dropdown-menu {
    height: 140px !important;
    overflow-y: visible !important;
  }

  .homeSliderMobile {
    height: 100% !important;
    margin-bottom: 100px;
  }

  .awssld__wrapper {
    overflow: visible !important;
    padding-top: 60px;
  }

  .awssld__content {
    padding-top: 210px;
    overflow: visible !important;
    margin-top: 0;
  }

  .awssld__content > img {
    object-fit: contain !important;
    overflow: visible !important;
    width: 900px !important;
    height: 900px !important;
    padding-bottom: 50px;
    top: 0;
    position: static !important;
    margin-bottom: 100px !important;
  }

  .btn-preview-popup {
    height: 90px;
  }

  .footer-img {
    width: 90%;
  }

  .timelineCalendar {
    flex: 1 1 auto;
  }

  .hiw-button-2 {
    padding-left: 15% !important;
    margin-top: 20px !important;
  }

  .youtubeEmbed {
    height: 350px;
  }
}

@media only screen and (max-width: 852px) {
  .btn-center {
    display: inline-block;
    float: none !important;
    left: 50%;
    position: relative;
    transform: translate(-50%);
  }

  .btn-share {
    margin-top: 4% !important;
  }

  .navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
    color: white !important;
  }

  .reset-password-card {
    background-color: white;
    border-radius: 16px;
  }

  .reset-password-form {
    padding: 10px 10px !important;
  }

  .supportPage {
    text-align: left;
  }

  .supportPage label {
    padding-left: 5px;
    font-weight: 700;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .profile-options {
    border: 0px solid black;
    height: 200px;
    background-color: white !important;
    font-family: 'Cerebri Sans';
    width: 50%;
  }

  .profile-posts {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }

  .inputLabel {
    font-family: 'Source Sans Pro', sans-serif;
    color: #434143 !important;
    font-size: 1rem;
    display: block;
    padding-right: 0%;
    text-align: left;
  }

  .separator-left {
    margin: 20px;
    overflow: hidden;
    color: #e75833;
    text-align: left;
    font-size: 2.3em !important;
  }

  .separator-left:before {
    border-top: 0px solid #e75833;
  }

  .separator-left::after {
    display: block;
    float: left;
    margin-top: 0.8em;
    /* half the line-height */
    border-top: 2px solid #e75833;
    width: 90%;
    content: '';
  }

  #footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 12em;
    margin-top: 2em;
  }

  #preauthfooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 12em;
    background-color: #57786c;
  }

  .preauthfooter {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 10em !important;
    background-color: #57786c;
  }

  .inputLabel {
    font-family: 'Source Sans Pro', sans-serif;
    color: #434143 !important;
    font-size: 1rem;
    display: block;
    padding-left: 0;
    text-align: left;
  }

  .banner {
    height: 350px;
  }

  .banneroverlay {
    font-size: 1.7em;
    padding-top: 150px;
  }

  .blogBannerOverlaySubtitle {
    font-size: 1.7em;
    padding-left: 8px;
    padding-right: 8px;
    margin-top: 0;
  }

  .blogbody {
    max-width: 90% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
