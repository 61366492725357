.event-container {
  .options-dropdown {
    float: right;
    margin: 0 0 0 auto;
    & > .dropdown {
      margin-top: -3px;
    }
    .btn {
      margin: 0 25px 0;
      padding: 0;
    }
  }
  & > .row * {
    margin: 0;
    padding: 0;
    line-height: 1.2;
    .btn {
      margin-left: 0.3em;
      padding: 0;
    }
    .select-row {
      padding: 3px auto;
      .select__control {
        padding: 3px;
        width: 100%;
        & .select__multi-value {
          padding: 3px;
          margin: 1px;
        }
      }
    }
    p.text-muted {
      font-size: 1em;
    }
  }
}

.select-container {
  width: 30%;
  margin-right: 20px;
}

@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~@fullcalendar/list/main.css';

// hiding time in fullcalendar, currently it's all defaulting to "all day"
.fc-list-item-time {
  display: none;
}
